import { isCreated } from "../utils/model";
import { OfferTemplateFieldType, OfferTemplateInsert, OfferTemplateInsertType } from "./OfferTemplateInsert";
import { Visibility } from "./Visibility";
import User from "./User";
import { OfferStatusLinks, SignedOfferLink } from "./OfferStatusLinks";
import { OfferTemplateMessageTemplateConfig } from "./OfferTemplateMessageTemplateConfig";
import { AttachmentConfiguration } from "./AttachmentConfiguration";
import { text } from "../utils/i18n";
import Issuer from "./Issuer";

export class OfferTemplateOrderConfiguration {
  readonly enabled?: boolean;
  readonly tagIds?: string[];
  readonly additionalFieldIds: string[] = [];

  constructor(deriveFrom?: Partial<OfferTemplateOrderConfiguration>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }
  }
}

export class OfferTemplateDefaultProduct {
  readonly productId: string;
}

export enum OfferTemplateExternalDocumentStatus {
  Waiting = 'waiting',
  Ready = 'ready',
  Error = 'error'
}

/**
 * Base model for offers.
 */
export class OfferTemplate {
  readonly id: string;
  readonly title: string = text`Ny mall`;
  /**
   * @deprecated Unused since the removal of Components
   */
  readonly type = "externalDocument";
  readonly externalDocumentFile?: File;

  /**
   * Renderable images of each page.
   */
  readonly externalDocumentPageImages?: OfferTemplateExternalDocumentPage[];
  readonly externalDocument?: {
    /**
     * Original uploaded file.
     */
    file: {
      uri: string;
      type: string;
      pageMetadata?: any;
    };

    status?: OfferTemplateExternalDocumentStatus;

    /**
     * URL of the processed document, if any.
     */
    documentUrl?: string;
    pageCount: number;
    pageMetadata: any[];
  };

  /**
   * For offer templates of type ExternalDocuments only.
   */
  readonly inserts: OfferTemplateInsert[] = [];

  /**
   * ID of a (removed) offer template which this template derives from.
   */
  readonly parentId?: string;

  /**
   * Whether this template is removed but required for
   * existing offers.
   */
  readonly isRemoved?: boolean;
  readonly isVoucherEnabled: boolean = true;

  /**
   * Default sign method.
   * 
   * Note: if an instance of Offer is available, then prioritize the offer's sign method.
   */
  readonly signMethod: OfferSignMethod = OfferSignMethod.Draw;
  readonly allowedSignMethods?: OfferSignMethod[];
  readonly statusLinks: OfferStatusLinks = new OfferStatusLinks();
  readonly orderConfiguration?: OfferTemplateOrderConfiguration;
  readonly messageTemplateConfiguration?: OfferTemplateMessageTemplateConfig;
  readonly visibility?: Visibility;
  readonly defaultProducts: OfferTemplateDefaultProduct[] = [];
  readonly productCategoryIds: string[] = [];
  readonly productIds: string[] = [];
  readonly productPrefixes: string[] = [];
  /**
   * Null means any project or none.
   */
  readonly projectIds?: string[] = null;

  readonly attachmentConfiguration?: AttachmentConfiguration;

  readonly additionalPages: {
    /**
     * Matches with `additionalPageFiles.id`.
     */
    id: string;
    url?: string;
  }[] = [];

  /**
   * Files to be uploaded as additional pages.
   */
  readonly additionalPageFiles: { id: string; file: File }[] = [];

  /**
   * UI only. When isCreated=false, after creating the offer template,
   * update the web form to include this offer template.
   */
  readonly addToWebFormIds?: string[];
  readonly removeFromWebFormIds?: string[];
  readonly issuer?: Issuer;

  constructor(deriveFrom?: Partial<OfferTemplate>) {
    if (!deriveFrom) {
      return;
    }

    Object.assign(this, deriveFrom);

    if (this.inserts) {
      this.inserts = this.inserts.map(insert => new OfferTemplateInsert(insert));
    }

    if (this.visibility) {
      this.visibility = new Visibility(this.visibility);
    }

    if (this.orderConfiguration) {
      this.orderConfiguration = new OfferTemplateOrderConfiguration(this.orderConfiguration);
    }

    if (!this.statusLinks) {
      this.statusLinks = new OfferStatusLinks({
        signed: new SignedOfferLink({
          message: this.signMethod === OfferSignMethod.OneClick ? text`Dokumentet har godkänts.` : text`Dokumentet har signerats.`
        })
      });
    } else {
      this.statusLinks = new OfferStatusLinks(this.statusLinks);
    }

    if (this.attachmentConfiguration) {
      this.attachmentConfiguration = new AttachmentConfiguration(this.attachmentConfiguration);
    }

    if (this.issuer) {
      this.issuer = new Issuer(this.issuer);
    }
  }

  /**
   * @returns Whether the user has changed the offer's external document, without saving.
   */
  get hasExternalDocumentChanged(): boolean | undefined {
    return Boolean(this.externalDocumentFile ||
      (this.externalDocument?.file.uri && !this.externalDocument.file.uri.startsWith('http')));
  }

  get externalDocumentUrl() {
    return this.externalDocument?.documentUrl ?? this.externalDocument?.file.uri;
  }

  get areAttachmentsEnabled() {
    return Boolean(this.attachmentConfiguration);
  }

  get isCreated() {
    return isCreated(this.id);
  }

  isVisible(currentUser: User) {
    return !this.visibility || this.visibility.isVisible(currentUser);
  }

  listOrderFieldIds(): string[] {
    const fieldIds: string[] = [];

    this.inserts?.forEach(insert => {
      if (insert.fieldType === OfferTemplateFieldType.OrderField && insert.fieldId &&
        !fieldIds.includes(insert.fieldId)) {

        fieldIds.push(insert.fieldId);
      }
    });

    this.orderConfiguration?.additionalFieldIds?.forEach(fieldId => {
      if (fieldId && !fieldIds.includes(fieldId)) {
        fieldIds.push(fieldId);
      }
    })

    return fieldIds;
  }
}

export enum OfferSignMethod {
  Draw = 'draw',
  OneClick = 'oneClick',
  BankID = 'bankId',
  None = 'none'
}

export class OfferTemplateExternalDocumentPage {
  uri: string;
  type: string;
  width: number;
  height: number;
}