import React, { CSSProperties, useRef } from 'react';
import cls from "classnames";
import { pad, eq } from '../../../../../utils';
import { TimeInput } from './TimeInput';
import moment from 'moment';
import styles from "./TimeSelect.module.scss";
import { Select } from '../../../../core/Select/Select';

type Time = {
  hour: number;
  minute: number;
};

function totalMinutes(t: Time) {
  return t.hour * 60 + t.minute;
}

function fromDate(date: Date): Time {
  return { hour: date.getHours(), minute: date.getMinutes() };
}

function toDate(t: Time): Date {
  return moment().hour(t.hour).minute(t.minute).toDate();
}

function fromMinutes(mins: number): Time {
  return { hour: Math.floor(mins / 60), minute: mins % 60 };
}

type Props = {
  rangeFrom?: Time | Date;
  rangeTo?: Time | Date;
  value?: Time | Date;
  onChange: (value: Time) => void;
  className?: string;

  containerStyle?: CSSProperties;

  /**
   * Time step, in minutes.
   */
  step?: number;

  hourTestId?: string;
  minuteTestId?: string;
  selectTestId?: string;
  readOnly?: boolean;
  inputStyle?: CSSProperties;
}

/**
 * A select component for selecting time within a range.
 */
export const TimeSelect: React.FC<Props> = ({
  value,
  onChange,
  className,
  containerStyle,
  rangeFrom = { hour: 0, minute: 0 },
  rangeTo = { hour: 23, minute: 45 },
  step = 15,
  selectTestId,
  hourTestId,
  minuteTestId,
  readOnly,
  inputStyle
}) => {
  if (value instanceof Date) {
    value = { hour: value.getHours(), minute: value.getMinutes() } as Time;
  }

  if (rangeFrom instanceof Date) {
    rangeFrom = fromDate(rangeFrom);
  }

  if (rangeTo instanceof Date) {
    rangeTo = fromDate(rangeTo);
  }

  const preventOnBlurChange = useRef(false);
  const minuteDiff = Math.abs(totalMinutes(rangeTo) - totalMinutes(rangeFrom));
  const data: Time[] = new Array(Math.floor(minuteDiff / step));

  for (let i = 0, n = data.length; i < n; i++) {
    data[i] = fromMinutes(totalMinutes(rangeFrom) + step * i);
  }

  return (
    <Select
      style={containerStyle}
      className={cls([styles.timeSelect, className])}
      value={value ? String(totalMinutes(value as Time)) : null}
      options={data.map(data => ({
        props: { "data-time-input-prevents-click": "false" }, //  Prevent TimeInput from preventing click if selected option.
        text: getTimeLabel(data),
        value: String(totalMinutes(data)),
        autoScrollTo: isCloseToSteppedTime(value || new Date(), data, step)
      }))}
      onChange={minute => {
        if (!isNaN(minute)) {
          onChange(fromMinutes(Number(minute)))
          preventOnBlurChange.current = true;
        }
      }}
      inputLike
      noChevron
      activeValueComponent={(
        <TimeInput
          value={value ? toDate(value as Time) : null}
          onBlurChange={date => {
            if (!preventOnBlurChange.current) {
              onChange(fromDate(date));
            }

            preventOnBlurChange.current = false;
          }}
          hourTestId={hourTestId}
          minuteTestId={minuteTestId}
          readOnly={readOnly}
          inputStyle={inputStyle} />
      )}
      hideDropdownOnBlur
      testId={selectTestId}
      readOnly={readOnly}
    />
  )
}

function getTimeLabel(t: Time, durationFrom?: Time) {
  const base = `${pad(t.hour)}:${pad(t.minute)}`;

  if (durationFrom) {
    const durationInMinutes = totalMinutes(t) - totalMinutes(durationFrom);
    if (durationInMinutes <= 0) {
      return base;
    }

    const duration = fromMinutes(durationInMinutes);

    let durationStr = '';
    if (duration.hour) {
      durationStr = `${duration.hour}h `;
    }
    if (duration.minute) {
      durationStr += `${duration.minute}m`;
    }

    return (<>{base} <span className="dimmed">&nbsp;&nbsp;{durationStr.trim()}</span></>) as any;
  }

  return base;
}

/**
 * Returns whether `date` is closest to `steppedTime` which has the been stepped with `step`.
 */
function isCloseToSteppedTime(date: Date | Time, steppedTime: Time, step: number) {
  const hour = date instanceof Date ? date.getHours() : date.hour;
  const minute = date instanceof Date ? date.getMinutes() : date.minute;

  return Math.floor((hour * 60 + minute) / step) ===
    Math.floor((steppedTime.hour * 60 + steppedTime.minute) / step);
}