type InteractionListener = {
  onClick: (button: number, element: HTMLElement, elementPath: string) => void;
  onKeyPress: (key: string, element: HTMLElement, elementPath: string) => void;
  onPaste: (text: string, element: HTMLElement, elementPath: string) => void;
};

let state: { interactionListener: InteractionListener; } = { interactionListener: null };

const ignoreAttributeNames = [
  'class',
  'style',
  'role'
];

function getElementPath(elements: HTMLElement[] | null | undefined) {
  if (!elements) {
    return '';
  }

  return elements.reverse().filter(element =>
    element.tagName && !/document|body|html/i.test(element.tagName) && (element as any) !== window
  ).map(element => {
    let description = element.tagName.toLowerCase();

    if (element.classList?.length) {
      description += [...element.classList]
        // Remove the trailing CSS modules hash since it is irrelevant and reduces readability. 
        .reduce((list, className) => list + '.' + className.replace(/__[\w\d]{5}$/, ''), '');
    }

    for (const attribute of element.attributes) {
      if (attribute.name === "value" && (
        element.getAttribute('type') === 'password' ||
        element.getAttribute('autocomplete')?.includes('password')
      )) {
        continue;
      }
      
      if (ignoreAttributeNames.includes(attribute.name)) {
        continue;
      }

      description += `[${attribute.name}=${attribute.value}]`;
    }

    if (element.innerText && /button|label/i.test(element.tagName)) {
      return `${description} (${element.innerText})`;
    }

    return description;
  }).join(' ');
}

/**
 * Handles clicking anywhere on the page.
 */
function onClick(event: MouseEvent) {
  const path = event.composedPath?.();
  if (!path?.length) {
    return;
  }

  state.interactionListener?.onClick(
    event.button,
    event.target as HTMLElement,
    getElementPath(path.map(element => element as HTMLElement))
  );
}

/**
 * Handles pressing any key.
 */
function onKeyPress(event: KeyboardEvent) {
  const path = event.composedPath?.();

  state.interactionListener?.onKeyPress(
    event.key,
    event.target as HTMLElement,
    getElementPath(path?.map(element => element as HTMLElement))
  );
}

function onPaste(event: ClipboardEvent) {
  const path = event.composedPath?.();

  state.interactionListener?.onPaste(
    event.clipboardData?.getData("text") || '',
    event.target as HTMLElement,
    getElementPath(path?.map(element => element as HTMLElement))
  );
}

export function enableInteractionLogging(listener: InteractionListener) {
  state.interactionListener = listener;

  document.addEventListener('click', onClick, { capture: true });
  document.addEventListener('keypress', onKeyPress, { capture: true });
  document.addEventListener('paste', onPaste, { capture: true });
}

export function disableInteractionLogging() {
  state.interactionListener = null;

  document.removeEventListener('click', onClick, { capture: true });
  document.removeEventListener('keypress', onKeyPress, { capture: true });
  document.removeEventListener('paste', onPaste, { capture: true });
}

