import { eq } from "../utils";
import { createTemporaryId } from "../utils/model";

export class OfferInsertProductSelector {
  /**
   * If set, then select the product at a specific index.
   * 
   * Unset to select the product at the same position as the insert, by checking insertNumber.
   */
  readonly index?: number;

  /**
   * Limits the selectable products to one or more categories.
   */
  readonly categoryIds?: string[];

  /**
   * Limits the selectable products to those with a name that matches this string.
   * 
   * Accepts regexp (boundaries and flags must be specified).
   * 
   * @example "id-skydd"
   * @example "/id-skydd (singel|total)/ig"
   */
  readonly productName?: string;

  /**
   * Functions exactly like `productName` but acts on the category name.
   */
  readonly categoryName?: string;

  constructor(deriveFrom?: Partial<OfferInsertProductSelector>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }
  }
}

export class OfferInsertPartSelector {
  readonly index?: number;

  constructor(deriveFrom?: Partial<OfferInsertPartSelector>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }
  }
}

/**
 * Represents an insert of information for an external document offer template.
 */
export class OfferTemplateInsert {

  readonly id = createTemporaryId();
  readonly key = createTemporaryId();

  readonly type?: OfferTemplateInsertType;

  /**
   * For field inserts only.
   */
  readonly fieldId?: string = null;

  /**
   * For field inserts only.
   */
  readonly fieldType?: OfferTemplateFieldType = null;

  /**
   * For field inserts only.
   */
  readonly fieldLabel?: string = null;

  readonly partSelector = new OfferInsertPartSelector({ index: null });

  readonly productSelector = new OfferInsertProductSelector({ index: null });

  /**
   * Text size of a field. Measured in pt.
   * For field inserts only.
   */
  readonly textSize?: number = 11;

  /**
   * Left position coordinate, in percentage of the page width, from 0 to 100.
   */
  readonly left: number;

  /**
   * Top position coordinate, in percentage of the page height, from 0 to 100.
   */
  readonly top: number;

  /**
   * Width in percentage of the page width, from 0 to 100.
   * For signature inserts only.
   */
  readonly width?: number;

  /**
   * Height in percentage of the page height, from 0 to 100.
   * For signature inserts only.
   */
  readonly height?: number;

  /**
   * Which page the insert is on.
   * First page is 1.
   */
  readonly page: number = 1;

  /**
   * Optional suffix.
   * For field inserts only.
   */
  readonly suffix?: string;

  constructor(deriveFrom?: Partial<OfferTemplateInsert>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }

    if (this.type === OfferTemplateInsertType.Signature &&
      typeof this.width !== 'number' && typeof this.height !== 'number') {
      this.width = 12.5;
      this.height = 7;
    }

    this.suffix = this.suffix ?? this.getDefaultSuffix();
  }

  /**
   * Returns the sequence number of this insert, given a set of inserts.
   * If an insert beside this one has the same reference field as this one,
   * or is also a signature location, then the insert number is incremented, starting from 1.
   * 
   * The returned sequence number is ordered by the insert's index in `inserts`.
   * 
   * If this is a unique insert and thus lacks a insert number, null is returned.
   */
  getInsertNumber(inserts: OfferTemplateInsert[]): number | null {
    const indexOfThisInsert = inserts.findIndex(otherInsert => otherInsert.id === this.id);

    //  Count inserts with the same fieldId before this insert
    const duplicateInserts = inserts.filter(otherInsert => otherInsert.id !== this.id && otherInsert.isSimilarTo(this));
    if (!duplicateInserts.length) {
      return null;
    }

    const previousDuplicateInserts = inserts.filter((otherInsert, otherInsertIndex) =>
      otherInsertIndex < indexOfThisInsert && otherInsert.isSimilarTo(this));

    return previousDuplicateInserts.length + 1;
  }

  get isNull() {
    if (this.type === OfferTemplateInsertType.Field &&
      !this.fieldId &&
      (!this.fieldType || this.fieldType === OfferTemplateFieldType.OrderField || this.fieldType === OfferTemplateFieldType.ProductField)) {
      return true;
    }

    return false;
  }

  isSimilarTo(other: OfferTemplateInsert) {
    if (this.id === other.id) {
      return true;
    }

    if (this.type === OfferTemplateInsertType.Signature && other.type === this.type) {
      return true;
    }

    if ((this.partSelector?.index ?? null) !== (other.partSelector?.index ?? null)) {
      return false;
    }

    if (this.fieldType?.startsWith("product") &&
      other.fieldType?.startsWith("product") &&
      (this.productSelector?.index ?? null) !== (other.productSelector?.index ?? null)) {
      return false;
    }

    return this.fieldType === other.fieldType && this.fieldId === other.fieldId;
  }

  getDefaultSuffix() {
    if (this.type !== OfferTemplateInsertType.Field) {
      return null;
    }

    if (this.fieldType === OfferTemplateFieldType.ProductPriceTotal ||
      this.fieldType === OfferTemplateFieldType.ProductPriceTotalExcludingVat ||
      this.fieldType === OfferTemplateFieldType.ProductPriceUnit ||
      this.fieldType === OfferTemplateFieldType.ProductPriceUnitExcludingVat ||
      this.fieldType === OfferTemplateFieldType.PriceTotal ||
      this.fieldType === OfferTemplateFieldType.PriceTotalExcludingVat ||
      this.fieldType === OfferTemplateFieldType.ProductVatTotal ||
      this.fieldType === OfferTemplateFieldType.ProductVatUnit ||
      this.fieldType === OfferTemplateFieldType.VatTotal) {
      return 'kr';
    }

    if (this.fieldType === OfferTemplateFieldType.ProductVatPercentage) {
      return '%';
    }

    if (this.fieldType === OfferTemplateFieldType.ProductQuantity) {
      return 'st.';
    }
  }

  allowsSuffix() {
    return this.fieldType === OfferTemplateFieldType.ProductPriceTotal ||
      this.fieldType === OfferTemplateFieldType.ProductPriceUnit ||
      this.fieldType === OfferTemplateFieldType.ProductPriceTotalExcludingVat ||
      this.fieldType === OfferTemplateFieldType.ProductPriceUnitExcludingVat ||
      this.fieldType === OfferTemplateFieldType.PriceTotal ||
      this.fieldType === OfferTemplateFieldType.PriceTotalExcludingVat ||
      this.fieldType === OfferTemplateFieldType.VatTotal ||
      this.fieldType === OfferTemplateFieldType.ProductQuantity ||
      this.fieldType === OfferTemplateFieldType.ProductVatTotal ||
      this.fieldType === OfferTemplateFieldType.ProductVatUnit ||
      this.fieldType === OfferTemplateFieldType.ProductVatPercentage;
  }

  /**
   * Returns whether this insert is of a type which is auto-filled
   */
  isAutoFilledImmediately() {
    return this.type === OfferTemplateInsertType.Field &&
      this.fieldType !== OfferTemplateFieldType.OrderField;
  }

  /**
   * Returns whether this insert is different from `other`.
   */
  equals(other: OfferTemplateInsert) {
    return eq({ ...this, key: null }, { ...other, key: null });
  }
}

export enum OfferTemplateInsertType {
  Field = 'field',
  Signature = 'signature'
}

export enum OfferTemplateFieldType {
  OrderField = 'orderField',
  ProductField = 'productField',
  CurrentDate = 'currentDate',
  SigneeName = 'signeeName',
  SignaturePlace = 'signaturePlace',
  ProductName = 'productName',
  ProductPriceTotal = 'productPriceTotal',
  ProductPriceUnit = 'productPriceUnit',
  ProductPriceTotalExcludingVat = 'productPriceTotalExcludingVat',
  ProductPriceUnitExcludingVat = 'productPriceUnitExcludingVat',
  ProductQuantity = 'productQuantity',
  ProductVatPercentage = 'productVatPercentage',
  ProductVatTotal = 'productVatTotal',
  ProductVatUnit = 'productVatUnit',
  PriceTotal = 'priceTotal',
  PriceTotalExcludingVat = 'priceTotalExcludingVat',
  VatTotal = 'vatTotal'
}