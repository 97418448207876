import { createTemporaryId } from "../utils/model";
import { OrderFieldReference } from "./Order";
import { ProductReference } from "./ProductReference";

export class OrderPart {
  readonly id: string = createTemporaryId();
  readonly fields: OrderFieldReference[] = [];
  readonly products: ProductReference[] = [];
  readonly name: string = "";

  constructor(deriveFrom?: Partial<OrderPart>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }
  }
}